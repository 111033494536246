import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {InputEmptyToNullDirective} from '../misc/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../misc/form-message/form-message.component';

@Component({
	selector:'app-index',
	standalone:true,
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./index.component.html',
	styleUrl:'./index.component.scss'
})
export class IndexComponent{
	private router:Router;
	private authenticationService:AuthenticationService;
	email:FormControl;
	password:FormControl;
	form:FormGroup;
	serverError?:string;
	serverResponse?:string;
	submitButtonActive:boolean;
	
	constructor(
		router:Router,
		authenticationService:AuthenticationService
	){
		this.router=router;
		this.authenticationService=authenticationService;
		this.submitButtonActive=true;
		this.serverError=undefined;
		this.serverResponse=undefined;
		this.email=new FormControl(undefined,[
			Validators.required,
			Validators.email
		]);
		this.password=new FormControl(undefined,[
			Validators.required
		]);
		this.form=new FormGroup({
			email:this.email,
			password:this.password
		});
		
		
	}
	
	login():void{
		if(this.submitButtonActive){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive=false;
				this.serverError=undefined;
				this.serverResponse=undefined;
				const next=async():Promise<void>=>{
					this.submitButtonActive=true;
					await this.router.navigate(['/admin']);
				};
				const error=(error:any):void=>{
					this.submitButtonActive=true;
					this.serverError=error;
				};
				this.authenticationService.login({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
	
}
