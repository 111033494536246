import {Component,inject,signal} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication/authentication.service';
import {FormBuilder,FormControl,Validators} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {InputEmptyToNullDirective} from '../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../misc/components/form-message/form-message.component';
import {Title} from '@angular/platform-browser';

@Component({
	selector:'app-index',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./index.component.html',
	styleUrl:'./index.component.scss'
})
export class IndexComponent{
	private router=inject(Router);
	private authenticationService=inject(AuthenticationService);
	private formBuilder=inject(FormBuilder);
	form=this.formBuilder.group({
		email:new FormControl<string|null>(null,{validators:[Validators.required,Validators.email]}),
		password:new FormControl<string|null>(null,{validators:[Validators.required]})
	});
	serverError=signal<any>(null);
	serverResponse=signal<any>(null);
	submitButtonActive=signal<boolean>(true);
	
	constructor(
		title:Title
	){
		title.setTitle('Bendita Administrator - Login');
		
	}
	
	login():void{
		if(this.submitButtonActive()){
			const {email,password}=this.form.getRawValue();
			const isNotValid=!email || !password;
			if(!this.form.valid || isNotValid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				this.authenticationService.login({email,password})
				.subscribe({
					next:async()=>{
						this.submitButtonActive.set(true);
						await this.router.navigate(['/admin']);
					},
					error:(error)=>{
						this.submitButtonActive.set(true);
						this.serverError.set(error);
					}
				});
			}
		}
	}
	
	
}
