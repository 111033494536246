import {Inject,Injectable} from '@angular/core';
import {HttpClient,HttpErrorResponse} from '@angular/common/http';
import {AppService} from '../app.service';
import {LoginRequestDto} from './dto/login-request.dto';
import {catchError,firstValueFrom,Observable,switchMap,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {UserResponseDto} from './dto/user-response.dto';
import {DOCUMENT} from '@angular/common';

@Injectable({
	providedIn:'root'
})
export class AuthenticationService{
	private readonly document:Document;
	private httpClient:HttpClient;
	private appService:AppService;
	private cookieService:CookieService;
	
	
	constructor(
		@Inject(DOCUMENT) document:Document,
		httpClient:HttpClient,
		appService:AppService,
		cookieService:CookieService
	){
		this.document=document;
		this.httpClient=httpClient;
		this.appService=appService;
		this.cookieService=cookieService;
		
		
		firstValueFrom(this.getUser()).then().catch((error)=>console.error(error.message));
	}
	
	login(data:LoginRequestDto):Observable<UserResponseDto>{
		return this.httpClient.post<UserResponseDto>(`${environment.apiServer.url}/api/authentication/login`,data,{withCredentials:true})
		.pipe(
			switchMap(this.getUser.bind(this))
		);
	}
	
	
	getUser():Observable<UserResponseDto>{
		return this.httpClient.get<UserResponseDto>(`${environment.apiServer.url}/api/authentication/user`,{withCredentials:true})
		.pipe(
			tap((response:UserResponseDto):void=>{
				this.appService.setUser(response);
			}),
			catchError((error:HttpErrorResponse):Observable<never>=>{
				this.appService.setUser(null);
				return throwError(()=>error);
			})
		);
	}
	
	logout():void{
		this.httpClient.post(`${environment.apiServer.url}/api/authentication/logout`,{},{withCredentials:true})
		.pipe(
			tap(():void=>{
				this.document.location.reload();
			}),
			catchError((error:HttpErrorResponse):Observable<never>=>{
				this.document.location.reload();
				return throwError(()=>error);
			})
		)
		.subscribe({next:console.log,error:console.error});
	}
}
