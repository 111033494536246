import {ApplicationConfig,inject,isDevMode,provideExperimentalZonelessChangeDetection,Provider} from '@angular/core';
import {provideRouter,withInMemoryScrolling,withPreloading} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration,withI18nSupport,withIncrementalHydration} from '@angular/platform-browser';
import {provideServiceWorker} from '@angular/service-worker';
import {provideHttpClient,withFetch,withInterceptors,withJsonpSupport} from '@angular/common/http';
import {AppService} from './app.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from './authentication/authentication.service';
import {authenticationInterceptor} from './authentication/authentication.interceptor';
import {provideNgxWebstorage,withLocalStorage,withNgxWebstorageConfig,withSessionStorage} from 'ngx-webstorage';
import {SelectivePreloadingStrategyService} from './common/selective-preloading-strategy.service';
import {MAT_DATE_FORMATS,MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {provideApollo} from 'apollo-angular';
import {ApolloLink,InMemoryCache} from '@apollo/client/core';
import {environment} from '../environments/environment';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HttpLink} from 'apollo-angular/http';
import {DATE_PIPE_DEFAULT_OPTIONS} from '@angular/common';

const appDefaultOptions:Provider[]=[
	{
		provide:DATE_PIPE_DEFAULT_OPTIONS,
		useValue:{
			dateFormat: 'd MMM y, HH:mm',
			timezone: '-0500'
		}
	},
	{provide:MAT_DATE_LOCALE,useValue:'en-US'},
	{
		provide:MAT_DATE_FORMATS,
		useValue:{
			parse:{
				dateInput:['l','LL']
			},
			display:{
				dateInput:'L',
				monthYearLabel:'MMM YYYY',
				dateA11yLabel:'LL',
				monthYearA11yLabel:'MMMM YYYY'
			}
		}
	},
	{
		provide:MAT_DIALOG_DEFAULT_OPTIONS,
		useValue:{
			disableClose:false,
			hasBackdrop:true,
			autoFocus:'first-tabbable',
			closeOnNavigation:true,
			panelClass:'dialog-responsive',
			position:{top:'10px'}
		}
	},
	{
		provide:MAT_SNACK_BAR_DEFAULT_OPTIONS,
		useValue:{
			duration:3000,
			horizontalPosition:'center',
			verticalPosition:'top',
			panelClass:'dialog-responsive'
		}
	}
];

export const appConfig:ApplicationConfig={
	providers:[
		provideExperimentalZonelessChangeDetection(),
		provideRouter(
			routes,
			withPreloading(SelectivePreloadingStrategyService),
			withInMemoryScrolling({
				scrollPositionRestoration:'enabled'
			})
		),
		provideClientHydration(
			withIncrementalHydration(),
			withI18nSupport()
		),
		provideServiceWorker(
			'ngsw-worker.js',
			{
				enabled:!isDevMode(),
				registrationStrategy:'registerWhenStable:30000'
			}
		),
		provideAnimationsAsync(),
		provideHttpClient(
			withFetch(),
			withJsonpSupport(),
			withInterceptors([authenticationInterceptor])
		),
		provideApollo(()=>{
			const httpLink=inject(HttpLink);
			const link:ApolloLink=httpLink.create({uri:`${environment.apiServer.url}/graphql`,withCredentials:true});
			// link=onError((error:ErrorResponse)=>{
			// 	if(error.graphQLErrors) error.graphQLErrors.forEach((value:GraphQLFormattedError,index:number,array:readonly GraphQLFormattedError[]):void=>console.error(`[GraphQL error]:`,error));
			// 	if(error.networkError) console.error('[Network error]:',error.networkError);
			// }).concat(link);
			return {
				link,
				cache:new InMemoryCache(),
				defaultOptions:{
					watchQuery:{
						fetchPolicy:'no-cache',
						errorPolicy:'all'
					},
					query:{
						fetchPolicy:'no-cache',
						errorPolicy:'all'
					},
					mutate:{
						fetchPolicy:'no-cache',
						errorPolicy:'none'
					}
				}
			};
		}),
		CookieService,
		provideNgxWebstorage(
			withNgxWebstorageConfig({prefix:'app',caseSensitive:true,separator:'-'}),
			withLocalStorage(),
			withSessionStorage()
		),
		AppService,
		AuthenticationService,
		...appDefaultOptions
	]
};
