<mat-card>
	<div>
		<div>
			<p>Welcome, enter your credentials</p>
		</div>
		<div class="form-container">
			<form [formGroup]="form" (ngSubmit)="login()">
				<mat-form-field floatLabel="auto">
					<input matInput type="email" placeholder="Email" name="email" formControlName="email" appInputEmptyToNull required>
				</mat-form-field>
				<mat-form-field floatLabel="auto">
					<input matInput type="password" placeholder="Contraseña" name="password" formControlName="password" appInputEmptyToNull required>
				</mat-form-field>
				<div>
					<app-form-message [serverError]="serverError()" [serverResponse]="serverResponse()"></app-form-message>
				</div>
				<div>
					<button type="submit" mat-raised-button [disabled]="!submitButtonActive()">Log in</button>
				</div>
			</form>
		</div>
	</div>
</mat-card>
