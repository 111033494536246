import {afterNextRender,Component,DestroyRef,inject,input,signal} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {DOCUMENT} from '@angular/common';
import {BreakpointObserver,Breakpoints,} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-drawer',
	imports:[
		SharedModule
	],
	templateUrl:'./drawer.component.html',
	styleUrl:'./drawer.component.scss'
})
export class DrawerComponent{
	drawer=input.required<MatDrawer>();
	destroyRef=inject(DestroyRef);
	breakpointObserver=inject(BreakpointObserver);
	appService=inject(AppService);
	authenticationService=inject(AuthenticationService);
	document=inject(DOCUMENT);
	matchesDark=signal<boolean|undefined>(undefined);
	isXSmallWindow=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	
	constructor(){
		afterNextRender(async()=>{
			if(this.document.defaultView){
				const darkColorScheme:MediaQueryList=this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');
				darkColorScheme.addEventListener('change',()=>{
					this.matchesDark.set(darkColorScheme.matches);
				});
				setTimeout(()=>darkColorScheme.dispatchEvent(new MediaQueryListEvent('change')),200);
			}
		});
	}
	
}
