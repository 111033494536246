import {Component,DestroyRef,inject,input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {BreakpointObserver,Breakpoints} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-header',
	imports:[
		SharedModule
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	drawer=input.required<MatDrawer>();
	appService=inject(AppService);
	authenticationService=inject(AuthenticationService);
	destroyRef=inject(DestroyRef);
	breakpointObserver=inject(BreakpointObserver);
	isXSmallWindow=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	
	
}
