import {Component,input} from '@angular/core';

@Component({
	selector:'app-form-message',
	imports:[],
	templateUrl:'./form-message.component.html',
	styleUrl:'./form-message.component.scss'
})
export class FormMessageComponent{
	serverError=input<any>();
	serverResponse=input<any>();
}
