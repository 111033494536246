import {Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {authenticationGuardActivate} from './authentication/authentication.guard';
import {adminGuardMatch} from './admin/admin.guard';

export const routes:Routes=[
	{
		path:'error',
		loadComponent:()=>import('./misc/error/error.component').then(m=>m.ErrorComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'',
		component:IndexComponent,
		pathMatch:'full',
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'login',
		component:IndexComponent,
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'admin',
		loadChildren:()=>import('./admin/admin.routes').then(m=>m.routes),
		canMatch:[adminGuardMatch]
	},
	{
		path:'**',
		loadComponent:()=>import('./misc/not-found/not-found.component').then(m=>m.NotFoundComponent),
		pathMatch:'full',
		data:{preload:true}
	}
];
