import {CanActivateFn,GuardResult} from '@angular/router';
import {CanMatchFn,Router} from '@angular/router';
import {CanActivateChildFn} from '@angular/router';
import {inject} from '@angular/core';
import {catchError,filter,firstValueFrom,map,of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';

export const authenticationGuardActivate:CanActivateFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user)=>user!==undefined),
		map((user)=>{
			if(user){
				return router.parseUrl('/admin');
			}else{
				return true;
			}
		}),
		catchError((error:HttpErrorResponse)=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const authenticationGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router=inject(Router);
	return firstValueFrom(inject(AuthenticationService).user.pipe(
		filter((user)=>user!==undefined),
		map((user)=>{
			if(user){
				return router.parseUrl('/admin');
			}else{
				return true;
			}
		}),
		catchError((error:HttpErrorResponse)=>{
			if(error.status===401){
				return of(true);
			}else{
				return of(true);
			}
		})
	));
};

export const authenticationGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	return true;
};
