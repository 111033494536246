<mat-toolbar role="heading">
	@defer (on immediate) {
		<mat-toolbar-row class="header-content">
			<button mat-icon-button class="menu-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" (click)="drawer().toggle()" aria-label="Toggle sidenav">
				<mat-icon>menu</mat-icon>
			</button>
			<a [routerLink]="['/']" rel="bookmark" class="logo-home">
				<img ngSrc="/icons/favicon.png" alt="home" [height]="(isXXSmallWindow | async)?.matches ? 50 : 70" [width]="(isXXSmallWindow | async)?.matches ? 50 : 70" priority>
			</a>
			<div class="spacer"></div>
			@if (authenticationService.user | async) {
				<a class="header-button-container" aria-label="profile" [matMenuTriggerFor]="menuLogin">
					<button mat-icon-button class="header-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'">
						<mat-icon aria-label="Profile">person</mat-icon>
					</button>
					<div>
						<span *ngIf="!(isXXSmallWindow | async)?.matches" class="app-12">{{(authenticationService.user | async)?.firstName}}</span>
						<span *ngIf="!(isXSmallWindow | async)?.matches" class="app-12">&nbsp;{{(authenticationService.user | async)?.lastName}}</span>
					</div>
					<mat-menu #menuLogin="matMenu">
						<mat-nav-list>
							<a mat-list-item (click)="authenticationService.logout()" (keydown)="authenticationService.logout()" dir="ltr" aria-label="login" role="listitem">
								<span matListItemTitle>Log out</span>
								<mat-icon matListItemIcon>logout</mat-icon>
							</a>
						</mat-nav-list>
					</mat-menu>
				</a>
			}
		</mat-toolbar-row>
	}
</mat-toolbar>

