import {HttpClient,HttpContext,HttpContextToken,HttpErrorResponse,HttpHandlerFn,HttpInterceptorFn,HttpRequest,HttpResponse} from '@angular/common/http';
import {BehaviorSubject,catchError,concat,filter,Observable,of,switchMap,take,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {inject,PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

const isRefreshing=new BehaviorSubject<boolean>(false);
export const SKIP_REQUEST=new HttpContextToken<boolean>(():boolean=>true);

const sendError=(error:any):Observable<never>=>{
	if(error.error instanceof ProgressEvent){
		return throwError(()=>new Error(error.message));
	}else if(error.error instanceof ErrorEvent){
		return throwError(()=>new Error(error.message));
	}else if(error.error instanceof HttpErrorResponse){
		return throwError(()=>new Error(error.error.message || error.error));
	}else{
		const graphqlError=error.graphQLErrors?.slice()?.shift()?.extensions?.response?.message;
		if(graphqlError){
			return throwError(()=>new Error(graphqlError));
		}else{
			return throwError(()=>error);
		}
	}
};

export const authenticationInterceptor:HttpInterceptorFn=(request:HttpRequest<any>,next:HttpHandlerFn):Observable<any>=>{
	const platformId=inject(PLATFORM_ID);
	const httpClient=inject(HttpClient);
	const refreshUrl=`${environment.apiServer.url}/api/authentication/refresh-jwt`;
	const context=new HttpContext().set(SKIP_REQUEST,true);
	
	if(isPlatformServer(platformId)) return next(request.clone());
	if(request.context.has(SKIP_REQUEST)) return next(request.clone());
	return next(request.clone())
	.pipe(
		filter((response)=>response.type!==0 && response instanceof HttpResponse),
		switchMap((response)=>{
			const errors:any[]=(response as HttpResponse<any>).body?.errors;
			if(errors){
				const unauthorizedError=errors.find((element)=>element?.extensions?.originalError?.statusCode===401);
				if(unauthorizedError){
					if(!isRefreshing.getValue()){
						isRefreshing.next(true);
						return httpClient.post(refreshUrl,{},{withCredentials:true,context})
						.pipe(
							tap(()=>{
								isRefreshing.next(false);
							}),
							switchMap(()=>httpClient.request(request.clone({context}))),
							catchError(()=>{
								isRefreshing.next(false);
								return of(response);
							})
						);
					}else{
						return isRefreshing.asObservable()
						.pipe(
							filter((value)=>!value),
							take(1),
							switchMap(()=>httpClient.request(request.clone({context})))
						);
					}
				}else return of(response);
			}else return of(response);
		}),
		catchError((error,caught)=>{
			if(error instanceof HttpErrorResponse){
				if(error.status===401){
					if(!isRefreshing.getValue()){
						isRefreshing.next(true);
						return concat(
							httpClient.post(refreshUrl,{},{withCredentials:true,context}),
							caught
						)
						.pipe(
							tap(()=>{
								isRefreshing.next(false);
							}),
							catchError((error)=>{
								isRefreshing.next(false);
								return sendError(error);
							})
						);
					}else{
						return isRefreshing.asObservable()
						.pipe(
							filter((value)=>!value),
							take(1),
							switchMap(()=>caught)
						);
					}
				}else return sendError(error);
			}else return sendError(error);
		})
	);
};
