/* eslint-disable @typescript-eslint/no-explicit-any*/
import {HttpClient,HttpContext,HttpContextToken,HttpErrorResponse,HttpEvent,HttpHandlerFn,HttpInterceptorFn,HttpRequest,HttpResponse} from '@angular/common/http';
import {BehaviorSubject,catchError,concat,filter,Observable,of,switchMap,take,tap,throwError} from 'rxjs';
import {environment} from '../../environments/environment';
import {inject,PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';

const isRefreshing:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
export const SKIP_REQUEST:HttpContextToken<boolean>=new HttpContextToken(():boolean=>true);

const sendError=(error:any):Observable<never>=>{
	if(error.error instanceof ProgressEvent){
		return throwError(():Error=>new Error(error.message));
	}else if(error.error instanceof ErrorEvent){
		return throwError(():Error=>new Error(error.message));
	}else if(error.error instanceof HttpErrorResponse){
		return throwError(():Error=>new Error(error.error.message || error.error));
	}else{
		const graphqlError:any=error.graphQLErrors?.slice()?.shift()?.extensions?.response?.message;
		if(graphqlError){
			return throwError(():Error=>new Error(graphqlError));
		}else{
			return throwError(():any=>error);
		}
	}
};

export const authenticationInterceptor:HttpInterceptorFn=(request:HttpRequest<any>,next:HttpHandlerFn):Observable<(HttpEvent<any>)>=>{
	const platformId:object=inject(PLATFORM_ID);
	const httpClient:HttpClient=inject(HttpClient);
	const refreshUrl:string=`${environment.apiServer.url}/api/authentication/refresh-jwt`;
	const context:HttpContext=new HttpContext().set(SKIP_REQUEST,true);
	
	if(isPlatformServer(platformId)) return next(request.clone());
	else if(request.context.has(SKIP_REQUEST)) return next(request.clone());
	else return next(request.clone())
		.pipe(
			filter((response:HttpEvent<any>):any=>response.type!==0 && response instanceof HttpResponse),
			switchMap((response:HttpEvent<any>):Observable<(HttpEvent<any>)>=>{
				const errors=(<HttpResponse<any>>response).body?.errors;
				if(errors){
					const unauthorizedError:any=errors.find((element:any):boolean=>element?.extensions?.originalError?.statusCode===401);
					if(unauthorizedError){
						if(!isRefreshing.getValue()){
							isRefreshing.next(true);
							return httpClient.post(refreshUrl,{},{withCredentials:true,context})
							.pipe(
								tap(():void=>{
									isRefreshing.next(false);
								}),
								switchMap(():(Observable<HttpEvent<any>>)=>httpClient.request(request.clone({context}))),
								catchError(():Observable<any>=>{
									isRefreshing.next(false);
									return of(response);
								})
							);
						}else{
							return isRefreshing.asObservable()
							.pipe(
								filter((value:boolean):boolean=>!value),
								take(1),
								switchMap(():(Observable<HttpEvent<any>>)=>httpClient.request(request.clone({context})))
							);
						}
					}else return of(response);
				}else return of(response);
			}),
			catchError((error:any,caught:Observable<any>):Observable<any>=>{
				if(error instanceof HttpErrorResponse){
					if(error.status===401){
						if(!isRefreshing.getValue()){
							isRefreshing.next(true);
							return concat(
								httpClient.post(refreshUrl,{},{withCredentials:true,context}),
								caught
							)
							.pipe(
								tap(():void=>{
									isRefreshing.next(false);
								}),
								catchError((error):Observable<any>=>{
									isRefreshing.next(false);
									return sendError(error);
								})
							);
						}else{
							return isRefreshing.asObservable()
							.pipe(
								filter((value:boolean):boolean=>!value),
								take(1),
								switchMap(():(Observable<HttpEvent<any>>)=>caught)
							);
						}
					}else return sendError(error);
				}else return sendError(error);
			})
		);
};
